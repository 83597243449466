<template>
  <b-form-group :label="label">
    <div
      class="custom-img dropzone dropzone-default dropzone-brand dz-clickable px-1 text-center"
      :class="value ? 'show-img' : ''"
    >
      <div class="dropzone-msg dz-message needsclick">
        <p
          v-if="!loadingImage"
          class="dropzone-msg-title"
        >
          {{ title }}
        </p>
        <b-spinner
          v-else
          variant="primary"
        />
        <span class="dropzone-msg-desc">{{ text }}</span>
      </div>
       <input
        type="file"
        class="input-file"
        accept=".jpg,.jpeg,.png"
        @input="onFileChange"
      >

      <img
        v-if="imgPreview || imgLink"
        :src="imgLink || imgPreview"
        class="img"
        alt="image"
        @error='handleImageError'
      >

      <slot name="delete_image" />
    </div>
  </b-form-group>
</template>

<script>
import { serialize } from 'object-to-formdata'

export default {
  props: {
    value: {
      default: '',
    },
    label: {
      default: 'Image',
    },
    title: {
      default: 'Drop files here or click to upload.',
    },
    text: {
      default: '',
    },
    type: {
      default: 'single',
    },
  },
  data() {
    return {
      imagePreviews: [],

      imgPreview: this.value,
      imgLink: null,
      loadingImage: false,
    }
  },
  watch: {
    value($val) {
      this.imgPreview = _.isString($val) ? $val : null
    },
  },
  methods: {
    handleImageError(event) {
      if (this.imagePreviews.length > 0) {
        event.target.src = this.imagePreviews[0]
      } else {
        event.target.src = require('@/assets/images/image.svg')
      }
    },
    onFileChange(e) {
      const { files } = e.target
      const $file = files[0]
      this.uploadFile($file)
    },
    uploadFile(file) {
      const form = {
        files: file,
        type: this.type,
      }

      const reader = new FileReader()
      reader.onload = (event) => {
        this.imagePreviews.push(event.target.result)
      }
      reader.readAsDataURL(file)

      const formData = serialize(form)
      this.loadingImage = true
      this.axios.post('/file/uploader/action', formData)
        .then(res => {
          this.loadingImage = false
          const { data } = res
          this.imgPreview = data.url
          this.imgLink = data.url
          this.$emit('input', data.name)
        })
        .catch(() => {
          this.loadingImage = false
        })
    },
    createInput(file) {
      const reader = new FileReader()
      const vm = this
      reader.onload = e => {
        vm.imgPreview = e.target.result
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
.custom-img {
  position: relative;
  border: 2px dashed #b27354 ;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;

  p {
    margin-bottom: 0;
    color: #b27354;
  }

  .input-file {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 3;
  }

  .img {
    margin: 0 auto;
    max-width: 100%;
    height: 120px;
    display: none;
  }

  &.show-img {
    padding: 0;

    .dropzone-msg {
      display: none;
    }

    .img {
      display: block;
    }
  }

  .delete-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 8;
    cursor: pointer;
    width: 12px;

    path {
      fill: #b90606;
    }
  }
}
</style>
