import Vue from 'vue'
import QuillEditor from '@/components/quill-editor/QuillEditorSnow.vue'
import TableIndex from '@/components/tableIndex.vue'
import LocaleTabs from '@/components/LocaleTabs.vue'
import DatePicker from '@/components/datePicker.vue'
import LoadingButton from '@/components/LoadingButton.vue'
import ImagePreview from '@/components/ImagePreview.vue'
import ImageUpload from '@/components/ImageUpload.vue'

Vue.component('QuillEditor', QuillEditor)
Vue.component(TableIndex.name, TableIndex)
Vue.component('LocaleTabs', LocaleTabs)
Vue.component('DatePicker', DatePicker)
Vue.component('LoadingButton', LoadingButton)
Vue.component('ImagePreview', ImagePreview)
Vue.component('ImageUpload', ImageUpload)
