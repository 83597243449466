import Vue from 'vue'
import axios from 'axios'
import UrlService from '@/services/url.service'

const axiosIns = axios.create({
  // eslint-disable-next-line import/no-named-as-default-member
  baseURL: UrlService.baseURL(),
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
