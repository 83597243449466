<template>
  <div>
    <b-form-group :label="label">
      <b-form-datepicker
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        :formatted-value="value"
        :value="value"
        :placeholder="placeholder ? label : ''"
        :locale="getLanguage"
        :label-help="''"
        :label-no-date-selected="''"
        :min="min"
        :max="max"
        :reset-button="reset"
        :label-reset-button="$t('labels.reset')"
        @context="onContext"
      />
    </b-form-group>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    reset: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getLanguage() {
      return localStorage.getItem('vuexy-direction') === 'ltr' ? 'en' : 'ar'
    },
  },
  methods: {
    onContext(ctx) {
      this.$emit('input', ctx.selectedYMD)
    },
  },
}
</script>
