// export const baseURL = () => 'https://dev-api.reinvestsa.com/api/v1/admin'
// export const googleKey = () => 'AIzaSyBRn_fnmWQ0-YA75DuCnEoOgfRwJBlkgIo'

// export const websiteMainUrl = () => 'https://dev-api.reinvestsa.com/api/v1'



export const baseURL = () => process.env.VUE_APP_BASE_URL
export const googleKey = () => process.env.VUE_APP_GOOGLE_KEY

export const mainURL = () => process.env.VUE_APP_MAIN_URL
export const websiteMainUrl = () => process.env.VUE_APP_WEBSITE_MAIN_URL
 
export const websiteFormUrl = () => process.env.VUE_APP_WEBSITE_FORM_URL
export const websiteListUrl = () => process.env.VUE_APP_WEBSITE_LIST_URL
 
export default { baseURL, googleKey, mainURL, websiteMainUrl, websiteFormUrl, websiteListUrl }
 