import Vue from 'vue'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BFormTimepicker,
  BFormCheckbox,
  BForm,
  BButton,
  BTable,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardFooter,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBTooltip,
  BListGroup,
  BListGroupItem,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckboxGroup,
  BButtonGroup,
  BFormRadioGroup,
  BFormFile,
  BSpinner,
  BTabs,
  BTab,
  BFormTags,
} from 'bootstrap-vue'

Vue.component('BRow', BRow)
Vue.component('BCol', BCol)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BFormInput', BFormInput)
Vue.component('BFormInput', BFormInput)
Vue.component('BFormTextarea', BFormTextarea)
Vue.component('BFormDatepicker', BFormDatepicker)
Vue.component('BFormTimepicker', BFormTimepicker)
Vue.component('BFormCheckbox', BFormCheckbox)
Vue.component('BForm', BForm)
Vue.component('BButton', BButton)
Vue.component('BTable', BTable)
Vue.component('BCard', BCard)
Vue.component('BCardTitle', BCardTitle)
Vue.component('BCardBody', BCardBody)
Vue.component('BCardHeader', BCardHeader)
Vue.component('BCardFooter', BCardFooter)
Vue.component('BCardText', BCardText)
Vue.component('BMedia', BMedia)
Vue.component('BMediaAside', BMediaAside)
Vue.component('BMediaBody', BMediaBody)
Vue.component('BAvatar', BAvatar)
Vue.component('BLink', BLink)
Vue.component('BBadge', BBadge)
Vue.component('BDropdown', BDropdown)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BPagination', BPagination)
Vue.component('BModal', BModal)
Vue.directive('b-tooltip', VBTooltip)
Vue.component('BListGroup', BListGroup)
Vue.component('BListGroupItem', BListGroupItem)
Vue.component('BFormSelect', BFormSelect)
Vue.component('BInputGroup', BInputGroup)
Vue.component('BInputGroupAppend', BInputGroupAppend)
Vue.component('BFormCheckboxGroup', BFormCheckboxGroup)
Vue.component('BButtonGroup', BButtonGroup)
Vue.component('BFormRadioGroup', BFormRadioGroup)
Vue.component('BFormFile', BFormFile)
Vue.component('BSpinner', BSpinner)
Vue.component('BSpinner', BSpinner)
Vue.component('BTabs', BTabs)
Vue.component('BTab', BTab)
Vue.component('BFormTags', BFormTags)
