<template>
  <div>
    <ul class="languages-tabs">
      <li
        v-for="(item,k,idx) in languages"
        :key="idx"
        :class="[selectedTab === idx ? 'active' : '',
                 (getFieldError(`${name}.${k}`) ? 'danger' : ''),
        ]"
        @click="selectTab(idx)"
      >
        {{ item }}
      </li>
    </ul>
    <slot
      v-for="(item,k,idx) in languages"
      :name="`lang${idx}`"
      :selectedTab="selectedTab"
    />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['errors', 'name'],
  data() {
    return {
      selectedTab: 0,
    }
  },
  computed: {
    languages() {
      return {
        en: this.$t('general.en'),
        ar: this.$t('general.ar'),
      }
    },
  },
  methods: {
    selectTab(selected) {
      this.selectedTab = selected
    },
    getFieldError(field) {
      let $errors = 0
      if (this.errors && this.errors[field]) {
        $errors = this.errors[field].length
      }
      return $errors
    },
  },
}
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}

.languages-tabs {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;

  > li {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #959cb6;
    margin-right: 15px;
    border-bottom: 2px solid transparent;
    transition: all 0.25s;

    &:hover {
      color: var(--primary);
    }

    &.active {
      color: var(--primary);
      border-color: var(--primary);
    }

    &.danger {
      color: var(--red);

      &.active {
        border-color: var(--red);
      }
    }
  }
}
</style>
