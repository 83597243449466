<template>
  <b-button
    :type="type"
    variant="primary"
    class="mr-1"
    :disabled="isLoading || loader"
  >
     <b-spinner
      v-if="isLoading || loader"
      small
      type="grow"
    />
    <span v-else>{{ buttonText }}</span>
  </b-button>
</template>
<script>

export default {
  name: 'LoadingButton',
  props: {
    buttonText: {
      type: String,
      default() {
        return this.$t('labels.submit')
      },
    },
    type: {
      type: String,
      default: 'submit',
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.app.loading
    },
  },
}
</script>
